<template>
	<div id="homepage">
		<!-- First section -->
		<div class="section headline">
			<h1>Więcej klientów dla Twojej firmy<br />w ciągu 90 dni,</h1>
			<h1 class="bigger"><a class="gold">Gwarantowane</a>.</h1>
		</div>

		<!-- CTA button -->
		<div class="section cta">
			<a class="button" @click="scrollToForm">TAK, CHCĘ TEGO!</a>
		</div>

		<!-- Marketing statement -->
		<div class="section statement">
			<h2>Marketing jest ważny...</h2>
			<h4>
				...ale na Twojej liście rzeczy do zrobienia jest już <a class="gold">101</a> innych zadań. <br />A one
				też są ważne!
			</h4>
			<h3>Więc Jak Możesz Wycisnąć Jak Najwięcej Z Twojego <a class="gold">Marketingu</a>?</h3>
		</div>

		<!-- Dismissal options section -->
		<div class="section dismiss-options">
			<div class="dismiss-box">
				<img class="ludzik" src="../assets/ludzik1.png" />
				<h4>Zajmować się wszystkim samemu?</h4>
				<p>
					Jeśli masz mało zajęć, to nie powinno być problemem.<br /><br />Jeśli jednak masz już dużo na
					głowie… to niestety nie jest możliwe.
				</p>
			</div>
			<div class="dismiss-box">
				<img class="ludzik" src="../assets/ludzik2.png" />
				<h4>Nowi pracownicy?</h4>
				<p>
					Znalezienie dobrych ludzi nie jest łatwe, a ich szkolenie jest kosztowne.<br /><br />A jeśli nawet
					uda Ci się znaleźć idealnego pracownika - nadal jesteś zależny od jednej osoby!
				</p>
			</div>
			<div class="dismiss-box">
				<img class="ludzik" src="../assets/ludzik3.png" />
				<h4>Zatrudnić ogromną agencję?</h4>
				<p>
					Nie masz budżetu setek tysięcy złotych?<br /><br />Twój marketing może być zrobiony przez stażystę
					asystenta innego asystenta. Nie brzmi to zbyt dobrze.
				</p>
			</div>
		</div>
		<hr style="width: 100%" />
		<!-- Guarantee section -->
		<div class="section guarantee">
			<h3>„Ok… Więc co sprawia, że się różnicie?”</h3>
			<p>Wygrywamy tylko wtedy, gdy Ty wygrasz. To podstawa dobrej współpracy.</p>
			<p>Dlatego zapewniamy naszym klientom gwarancję:</p>
			<h2>Pobijemy Wyniki Twojej Najlepszej Kampanii, Albo Nie Musisz Nam <a class="gold">Nic Płacić</a>.</h2>
			<p>Czy to nie brzmi ciekawie?</p>
			<p>Nie chcesz nas przetestować?</p>
			<p>Ale zaczekaj chwilę, nie tak szybko.</p>
			<p>Są trzy rzeczy, które musisz spełnić, żebyś mógł z tego skorzystać.</p>
			<div class="guarantee-conditions">
				<div class="guarantee-box">
					<span class="gold ultrabig">1.</span>
					<div>
						<h4>Nie możesz sprzedawać skamu</h4>
					</div>
				</div>
				<div class="guarantee-box">
					<span class="gold ultrabig">2.</span>
					<div>
						<h4>To my decydujemy, co według nas zadziała.</h4>
					</div>
				</div>
				<div class="guarantee-box">
					<span class="gold ultrabig">3.</span>
					<div>
						<h4>Musisz już coś sprzedawać.</h4>
					</div>
				</div>
			</div>
			<p>
				I to tyle. Nic skomplikowanego, prawda? Jeśli spełniasz te warunki, wypełnij formularz poniżej.
				Zobaczymy, co możemy dla Ciebie zrobić.
			</p>
		</div>

		<!-- Final call-to-action section -->
		<div class="section final-cta" id="form">
			<h2>
				Ty zajmiesz się tym, w czym jesteś <a class="gold">najlepszy</a>. My zajmiemy się znalezieniem dla
				Ciebie <a class="gold">klientów</a>.
			</h2>
			<h4>Chcesz wiedzieć, co możemy dla Ciebie zrobić? Wypełnij formularz, a my skontaktujemy się z Tobą.</h4>

			<!-- Form section -->
			<div class="headline-left-form">
				<form>
					<div class="input-container name-container">
						<i class="fas fa-user"></i>
						<input type="text" class="name" placeholder="Imię" v-model="name" />
					</div>
					<div class="input-container email-container">
						<i class="fas fa-envelope"></i>
						<input type="email" class="email" placeholder="E-Mail" v-model="email" />
					</div>
					<div class="input-container company-container">
						<i class="fas fa-building"></i>
						<input type="text" class="company" placeholder="Firma" v-model="company" />
					</div>
					<div class="input-container phone-container">
						<i class="fas fa-phone"></i>
						<input type="text" class="phone" placeholder="Telefon" v-model="phone" />
					</div>
					<div class="input-container question-container">
						<i class="fas fa-question"></i>
						<input
							type="text"
							class="question"
							placeholder="Jakie jest Twoje najważniejsze pytanie?"
							v-model="question" />
					</div>
					<div class="input-container message-container">
						<i class="fas fa-pencil-alt"></i>
						<textarea placeholder="Wiadomość" v-model="message"></textarea>
					</div>
					<button type="submit" @click.prevent="sendInfo">WYŚLIJ</button>
				</form>
			</div>

			<a ref="warningRef">{{ warningMessage }}</a>
		</div>
	</div>
</template>

<script>
	import { inject, ref, reactive, toRefs } from "vue";
	import axios from "axios";

	export default {
		setup() {
			document.title = "RMD Solutions - Marketing";
			const endpoint = inject("g_endpoint");

			const formData = reactive({
				name: "",
				email: "",
				company: "",
				phone: "",
				question: "",
				message: "",
			});

			const warningMessage = ref("");

			const warningRef = ref(null);

			const validateField = (condition, message) => {
				if (!condition) {
					warningMessage.value = message;
					if (warningRef.value) {
						warningRef.value.style.visibility = "visible";
					}
					return false;
				}
				return true;
			};

			const sendInfo = async () => {
				if (!validateField(formData.name !== "", "Podaj imię.")) return;
				if (!validateField(/^\S+@\S+\.\S+$/.test(formData.email), "Podaj poprawny adres email.")) return;
				if (!validateField(formData.company !== "", "Podaj nazwę firmy.")) return;
				if (!validateField(/^\d{9}$/.test(formData.phone), "Numer telefonu powinien składać się z 9 cyfr."))
					return;
				if (
					!validateField(
						formData.question !== "" || formData.message !== "",
						"Pytanie i wiadomość nie mogą być puste."
					)
				)
					return;

				try {
					let dataToSend = {
						name: formData.name,
						email: formData.email,
						company: formData.company,
						phone_number: formData.phone,
						main_question: formData.question,
						message: formData.message,
					};
					const jsonFormData = JSON.stringify(dataToSend);

					const response = await axios.post(`${endpoint}messages/`, jsonFormData, {
						withCredentials: true,
						headers: { "Content-Type": "application/json" },
					});

					Object.keys(formData).forEach((key) => (formData[key] = ""));
					warningMessage.value = "Wiadomość wysłana poprawnie";
				} catch (error) {
					console.error("Błąd podczas wysyłania danych:", error);
					warningMessage.value = "Wiadomość nie została wysłana";
				}
			};

			const scrollToForm = () => {
				const formElement = document.getElementById("form");
				if (formElement) {
					formElement.scrollIntoView({ behavior: "smooth" });
				}
			};

			return { ...toRefs(formData), warningMessage, sendInfo, scrollToForm, warningRef };
		},
	};
</script>
<style lang="scss" scoped>
	@import "../style/style.scss";
	.gold {
		color: $gold-color;
	}
	#homepage {
		display: flex;
		flex-direction: column;
		gap: 50px;
		max-width: 1200px;
		margin: auto;
		padding: 50px 20px;

		h1 {
			font-size: 4.5rem;
			margin: 0;

			&.bigger {
				font-size: 6rem;
			}
		}

		h2 {
			font-size: 3.4rem;
		}

		h3 {
			font-size: 2.3rem;
		}

		h4 {
			font-size: 1.5rem;
		}

		p {
			font-size: 1.2rem;
		}

		.ultrabig {
			font-size: 6rem;
		}

		.section {
			text-align: center;
			padding: 20px;
		}

		.cta {
			display: flex;
			justify-content: center;

			.button {
				background-color: $gold-color;
				color: white;
				border: none;
				padding: 10px 20px;
				font-size: 1.3rem;
				width: 50%;
				padding: 30px;
				cursor: pointer;
				border-radius: 1000px;
				transition: 0.3s ease-in-out;
				font-size: 2rem;

				&:hover {
					background-color: white;
					color: $gold-color;
				}
			}
		}

		.dismiss-options {
			display: flex;
			justify-content: space-around;
			gap: 30px;

			.dismiss-box {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 30%;
			}

			img.ludzik {
				max-width: 150px;
				margin-bottom: 15px;
			}
		}

		.guarantee-conditions {
			display: flex;
			justify-content: space-around;
			gap: 30px;

			.guarantee-box {
				flex: 1;
				text-align: center;

				div {
					text-align: left;
					margin-top: -30px;
				}
			}
		}

		.final-cta {
			.headline-left-form {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-top: 20px;

				form {
					width: 100%;
					display: flex;
					flex-direction: column;

					.input-container {
						position: relative;
						width: 100%;
						margin-bottom: 20px;

						input,
						textarea {
							width: 100%;
							padding: 15px 10px 15px 40px;
							border-radius: 5px;
							border: 1px solid #ddd;
							font-size: 1.2rem;
						}

						textarea {
							resize: none;
							min-height: 120px;
						}

						i {
							position: absolute;
							left: 10px;
							top: 50%;
							transform: translateY(-50%);
							color: $gold-color;
							font-size: 1.5rem;
						}
					}

					button {
						background-color: $gold-color;
						color: white;
						border: none;
						padding: 10px 20px;
						font-size: 1.3rem;
						width: 100%;
						padding: 30px;
						cursor: pointer;
						border-radius: 1000px;
						transition: 0.3s ease-in-out;

						&:hover {
							background-color: white;
							color: $gold-color;
						}
					}
				}
			}
		}

		@media (max-width: 768px) {
			gap: 30px;
			max-width: 768px;
			margin: 0;
			padding: 0;
			margin-top: 20px;

			h1 {
				font-size: 2.5rem;

				&.bigger {
					font-size: 3rem;
				}
			}

			h2 {
				font-size: 3rem;
			}

			h3 {
				font-size: 2.5rem;
			}

			h4 {
				font-size: 1.5rem;
			}

			p {
				font-size: 1.2rem;
			}

			.section {
				padding: 0;
			}

			.cta {
				.button {
					width: 100%;
					padding: 10px;
					font-size: 2rem;
				}
			}

			.dismiss-options {
				flex-direction: column;
				gap: 40px;

				.dismiss-box {
					width: 100%;
				}

				img.ludzik {
					margin-bottom: -20px;
				}
			}

			.guarantee-conditions {
				flex-direction: column;

				.guarantee-box {
					text-align: center;
				}
			}

			.final-cta {
				.headline-left-form {
					margin-bottom: 30px;
				}
			} //
		}
	}
</style>
