<template>
	<div class="article" v-if="article">
		<div class="article-author">
			<div class="article-author-photo">
				<img src="./blogAssets/Rafal.png" class="image-50" alt="author photo" />
			</div>
			<div class="article-author-name">
				<p>{{ article.author }}</p>
			</div>
			<div class="article-author-published">
				<p>{{ formatDate(article.publication_date) }}</p>
			</div>
			<div class="article-author-share">
				<img @click="shareArticle" src="./blogAssets/share.png" class="image-50 show-hand" alt="share icon" />
			</div>
		</div>
		<div class="article-title">
			<h1>{{ article.title }}</h1>
		</div>
		<div class="article-content" v-html="article.content"></div>

		<div class="article-info">
			<span class="article-info-views">{{ article.views_number }} wyświetleń</span>
			<span class="article-info-likes">{{ article.likes_number }} polubień </span>
			<button @click="incrementLikes" class="button" :disabled="hasLiked">
				{{ hasLiked ? "Polubione" : "Lubię To" }}
			</button>
		</div>
	</div>
	<div v-else>
		<p>Loading article...</p>
	</div>
</template>

<script>
	import { ref, onMounted } from "vue";
	import { useRoute } from "vue-router";
	import axios from "axios";

	export default {
		name: "articlePage",
		setup() {
			const route = useRoute();
			const article = ref(null);
			const hasLiked = ref(false);

			const fetchArticle = async (id) => {
				try {
					const response = await axios.get(`https://rmdsolutions.eu:8002/api/blog_posts/${id}`);
					article.value = response.data;
					document.title = article.value.title;

					// Sprawdź, czy użytkownik już polubił ten artykuł
					hasLiked.value = localStorage.getItem(`liked_${id}`) === "true";
				} catch (error) {
					console.error("Error fetching the article:", error);
				}
			};

			const incrementViews = async (id) => {
				try {
					await axios.put(`https://rmdsolutions.eu:8002/api/blog_posts/${id}/increment_views/`);
				} catch (error) {
					console.error("Error incrementing views:", error);
				}
			};

			const incrementLikes = async () => {
				if (hasLiked.value) return; // Zabezpieczenie przed wielokrotnym lajkiem

				try {
					await axios.put(`https://rmdsolutions.eu:8002/api/blog_posts/${route.params.id}/increment_likes/`);
					article.value.likes_number += 1;
					hasLiked.value = true;
					localStorage.setItem(`liked_${route.params.id}`, "true");
				} catch (error) {
					console.error("Error incrementing likes:", error);
				}
			};

			const formatDate = (dateString) => {
				const options = { year: "numeric", month: "long", day: "numeric" };
				return new Date(dateString).toLocaleDateString("pl-PL", options);
			};

			const shareArticle = () => {
				const articleUrl = window.location.href;
				const title = article.value.title;

				if (navigator.share) {
					navigator
						.share({
							title: title,
							url: articleUrl,
						})
						.then(() => {
							console.log("Article shared successfully");
						})
						.catch((error) => {
							console.error("Error sharing the article:", error);
						});
				} else if (navigator.clipboard) {
					navigator.clipboard
						.writeText(articleUrl)
						.then(() => {
							alert("Link do artykułu został skopiowany do schowka");
						})
						.catch((error) => {
							console.error("Error copying the link:", error);
						});
				} else {
					prompt("Skopiuj ten link do artykułu:", articleUrl);
				}
			};

			onMounted(() => {
				const articleId = route.params.id;
				fetchArticle(articleId);
				incrementViews(articleId);
			});

			return {
				article,
				formatDate,
				incrementLikes,
				shareArticle,
				hasLiked,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.article {
		width: 60%;
		max-width: 1000px;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 2%;
	}

	.image {
		&-50 {
			width: 50px;
			height: 50px;
			border-radius: 50%;
		}
	}

	.article-info {
		display: flex;
		justify-content: space-between;

		span {
			font-size: 1.5rem;
			margin: 0;
		}
	}

	.show-hand {
		cursor: pointer;
	}

	.article-image {
		width: 50%;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.article-title {
		font-size: 1.8em;
	}

	.article-about {
		font-size: 1.5em;
	}

	.article-content {
		font-size: 1em;
		width: 100%;
		margin-bottom: 3rem;

		:deep(p) {
			margin-top: 2rem;
			margin-bottom: 2rem;
			font-size: 1.3rem;

			a {
				color: red;
			}
		}

		:deep(p:has(strong)),
		:deep(p:has(em)) {
			margin-top: 2em;
			margin-bottom: 2em;
			text-align: center;
			font-size: 2rem;
		}
	}

	.article-author {
		display: grid;
		grid-template-columns: 50px 1fr 50px;
		grid-template-rows: 25px 25px;
		grid-template-areas:
			"photo name share"
			"photo published share";

		p {
			margin: 0;
		}

		.article-author-photo {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			grid-area: photo;
		}

		.article-author-name {
			font-size: 1em;
			margin-left: 5%;
			grid-area: name;
		}

		.article-author-published {
			font-size: 1em;
			margin-left: 5%;
			grid-area: published;
		}

		.article-author-share {
			width: 50px;
			height: 50px;
			margin-left: 5%;
			grid-area: share;
		}
	}

	.button {
		font-size: 1rem;
		padding: 0.5rem;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
		transition: 0.2s ease-in-out;
		border: 1px solid $gold-color;
		border-radius: 75px;
		text-decoration: none;
		color: $font-color;
		background-color: $gold-color;
		font-weight: 200;
		cursor: pointer;

		&:hover {
			background-color: $font-color;
			border: 1px solid $font-color;
			color: $gold-color;
		}
	}

	/* Mobile adjustments */
	@media (max-width: 768px) {
		.article {
			width: 100%;
			padding: 5%;
		}

		.image {
			&-50 {
				width: 40px;
				height: 40px;
			}
		}

		.article-info span {
			font-size: 1.2rem;
		}

		.article-image {
			width: 100%;
		}

		.article-title {
			font-size: 1em;
		}

		.article-content :deep(p:has(strong)),
		.article-content :deep(p:has(em)) {
			font-size: 1.5em;
		}

		.article-content :deep(p) {
			font-size: 1.2em;
		}

		.article-author-photo {
			width: 40px;
			height: 40px;
		}

		.article-author-name,
		.article-author-published {
			font-size: 0.9em;
		}

		.article-author-share {
			width: 40px;
			height: 40px;
		}

		.button {
			font-size: 1rem;
			padding: 3%;
		}
	}
</style>
