<template>
	<div id="baner">
		<router-link to="/" id="logo"><img src="../assets/logo.png" /></router-link>
		<div id="links">
			<!-- <router-link to="/analiza">Darmowa Analiza Marketingowa</router-link>
            <router-link to="/poradnik">Darmowy Poradnik</router-link>
            <router-link to="/blog">Blog</router-link> -->
			<img src="../assets/burger.png" id="menu" @click="switchMenu" />
		</div>
		<div id="fullScreen">
			<router-link to="/analiza">Darmowa Analiza Marketingowa</router-link>
			<!-- <router-link to="/poradnik">Darmowy Poradnik</router-link> -->
			<router-link to="/blog">Blog</router-link>
			<!-- <router-link to="/oferta">Co Możemy Dla Ciebie Zrobić</router-link> -->
			<!-- <router-link to="/oNas">O Nas</router-link> -->
			<!-- <router-link to="/portfolio">Portfolio</router-link> -->
			<!-- <router-link to="/opinie">Opinie</router-link> -->
			<router-link to="/kontakt">Kontakt</router-link>
			<img src="../assets/burger.png" id="closeMenu" @click="switchMenu" />
		</div>
		<img src="../assets/burger.png" id="menuOnly" @click="switchMenu" />
	</div>
</template>

<script>
	import { watch, onMounted } from "vue";
	import { useRoute } from "vue-router";

	export default {
		setup() {
			const route = useRoute();

			// Ensure the menu is hidden on initial load
			onMounted(() => {
				const fullScreenElement = document.getElementById("fullScreen");
				fullScreenElement.style.display = "none"; // Explicitly hide the menu
			});

			watch(route, () => {
				const fullScreenElement = document.getElementById("fullScreen");
				if (fullScreenElement.style.display === "flex") {
					switchMenu(); // Close the menu when route changes if it's open
				}
			});

			function disableScrolling() {
				var x = window.scrollX;
				var y = window.scrollY;
				window.onscroll = () => {
					window.scrollTo(x, y);
				};
			}

			function enableScrolling() {
				window.onscroll = null;
			}

			function switchMenu() {
				const fullScreenElement = document.getElementById("fullScreen");
				const isMenuVisible = fullScreenElement.style.display === "flex";

				if (isMenuVisible) {
					fullScreenElement.style.display = "none";
					enableScrolling();
				} else {
					fullScreenElement.style.display = "flex";
					disableScrolling();
				}
			}

			return { switchMenu };
		},
	};
</script>

<style lang="scss" scoped>
	@import "../style/style.scss";
	#baner {
		width: 1200px;
		max-width: 100vw;
		margin-left: auto;
		margin-right: auto;
		height: 100px;
		max-height: 6vh;

		top: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;

		background-color: $main-color;
		user-select: none;
		z-index: 1000;

		//border-bottom: 5px solid $gold-color;

		#menuOnly {
			display: flex;
			cursor: pointer;
			transition: 0.3s ease-in-out;
			height: 80%;
			background-size: contain;
			background-repeat: no-repeat;
		}

		#fullScreen {
			display: none;
			position: fixed;
			top: 6vh;
			left: 0;
			width: 100vw;
			height: 100vh;
			padding-top: 30vh;
			padding-bottom: 30vh;
			background-color: $main-color;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			z-index: 1000;

			a {
				font-size: 2rem;
				color: white;
				text-decoration: none;
				transition: 0.3s ease-in-out;
				text-align: center;

				&:hover {
					color: $gold-color;
				}
			}

			#closeMenu {
				cursor: pointer;
				transition: 0.3s ease-in-out;
				height: 2rem;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		#links {
			display: none;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			width: 40%;
			height: 100%;

			a {
				cursor: pointer;
				transition: 0.3s ease-in-out;
				white-space: nowrap;
				color: white;
				text-decoration: none;
				font-size: 1.1rem;

				&:hover {
					color: $gold-color;
				}
			}

			#menu {
				//display: none;
				cursor: pointer;
				transition: 0.3s ease-in-out;
				height: 80%;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		& #logo {
			height: 90%;
			background-size: contain;
			background-repeat: no-repeat;

			img {
				height: 100%;
			}
		}
	}

	@media only screen and (min-width: 1000px) {
		#menuOnly {
			display: none !important;
		}

		#links {
			display: flex !important;
		}
	}
</style>
