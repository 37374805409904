<template>
	<div id="main">
		<div id="part5" class="part">
			<h1>Darmowy <a class="gold">Poradnik</a>.</h1>
		</div>
		<div id="part6" class="part">
			<h2>Jak Łatwo Przyciągnąć Więcej Klientów <br />za Pomocą Meta Ads</h2>
		</div>
		<div id="part7" class="part">
			<h3>
				Facebook i Instagram są najszybszym i najbardziej opłacalnym sposobem, żeby dotrzeć do idealnych
				klientów dla Twojego biznesu. <br /><br />W tym darmowym poradniku dowiesz się, jak to osiągnąć
				skutecznie i szybko. Bez marketingowego żargonu, bez technicznego bełkotu. Prosto i krok po kroku.
			</h3>
			<div id="form">
				<input type="text" class="form50" placeholder="Imię" v-model="name" />
				<input type="email" class="form50" placeholder="E-Mail" v-model="email" />
				<div class="formconsent">
					<input type="checkbox" id="consent" v-model="consent" />
					<label for="consent"
						>Zgadzam się na wykorzystanie mojego adresu email do wysłania mi poradnika marketingowego i
						maili z poradami marketingowymi. Rozumiem, że zawsze mogę z nich zrezygnować.</label
					>
				</div>
				<a class="button" @click="sendInfo">WYŚLIJ PORADNIK</a>
			</div>
			<a ref="warningRef">{{ warningMessage }}</a>
		</div>
	</div>
</template>

<script>
	import { inject, ref, reactive, toRefs } from "vue";
	import axios from "axios";
	import { useRouter } from "vue-router"; // Correctly import useRouter

	export default {
		setup() {
			document.title = "Darmowy Poradnik";
			const endpoint = inject("g_endpoint");

			const formData = reactive({
				name: "",
				email: "",
				consent: false,
			});

			const warningMessage = ref("");
			const warningRef = ref(null);
			const router = useRouter(); // Initialize useRouter

			const validateField = (condition, message) => {
				if (!condition) {
					warningMessage.value = message;
					if (warningRef.value) {
						warningRef.value.style.visibility = "visible";
					}
					return false;
				}
				return true;
			};

			const sendInfo = async () => {
				if (!validateField(formData.name !== "", "Podaj imię.")) return;
				if (!validateField(/^\S+@\S+\.\S+$/.test(formData.email), "Podaj poprawny adres email.")) return;
				if (!validateField(formData.consent, "Musisz wyrazić zgodę na przetwarzanie danych.")) return;

				try {
					const response = await axios.post(
						"https://assets.mailerlite.com/jsonp/980239/forms/125831165546333698/subscribe",
						{
							fields: {
								name: formData.name,
								email: formData.email,
							},
							ml_submit: 1,
							anticsrf: "true",
						},
						{
							headers: { "Content-Type": "application/json" },
						}
					);

					Object.keys(formData).forEach((key) => (formData[key] = ""));
					formData.consent = false;
					warningMessage.value = "Wiadomość wysłana poprawnie";
					router.push("/dziekujemy"); // Use router.push instead of route.push
				} catch (error) {
					console.error("Błąd podczas wysyłania danych:", error);
					warningMessage.value = "Wiadomość nie została wysłana";
				}
			};

			return { ...toRefs(formData), warningMessage, sendInfo, warningRef };
		},
	};
</script>

<style lang="scss" scoped>
	@import "src/style/style.scss";
	#main {
		width: 100%;
		display: flex;
		flex-direction: column;

		.part {
			width: 100%;
			//padding-top: 5%;
			//padding-bottom: 5%;
		}

		a.gold {
			color: $gold-color;
		}

		#part5 h1 {
			font-weight: 300 !important;
		}

		#part6,
		#part5 {
			display: flex;
			justify-content: center;

			h2 {
				font-size: 1.5rem;
				line-height: 2.5rem;
				width: 90%;
				text-align: center;
			}

			h1 {
				font-size: 2rem;
				line-height: 2.5rem;
				width: 90%;
				text-align: center;
			}
		}

		#part7 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 5%;

			h3 {
				font-size: 1.2rem;
				width: 90%;
				line-height: 2rem;
			}

			#warning {
				margin-top: 1%;
				visibility: hidden;
			}

			#form {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 90%;

				input {
					padding: 1%;
					padding-left: 2%;
					margin: 0;
				}

				textarea {
					padding: 1%;
					padding-left: 2%;
					margin: 0;
					width: 100%;
					resize: none;
					height: 10rem;
					margin-top: 1%;
				}

				.form50 {
					width: 100%;
					margin-top: 1%;
					margin-bottom: 1%;
				}

				.form100 {
					width: 100%;
					margin-top: 1%;
					margin-bottom: 1%;
				}

				.formconsent {
					width: 100%;
					display: flex;
					align-items: center;
					margin-top: 1%;
					margin-bottom: 1%;

					input {
						width: auto;
						margin-right: 0.5rem;
					}
				}

				a {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		input,
		textarea {
			outline: none;
			background-color: $main-color;
			border: none;
			border-bottom: 1px solid white;
			color: white;
			font-size: 1rem;
		}

		.button {
			height: 3rem;
			font-size: 2rem;
			padding: 10%;

			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 5%;

			white-space: nowrap;
			transition: 0.2s ease-in-out;
			border: 1px solid $gold-color;
			border-radius: 75px;
			text-decoration: none;
			color: $font-color;
			background-color: $gold-color;
			font-weight: 200;
			cursor: pointer;

			&:hover {
				background-color: $font-color;
				border: 1px solid $font-color;
				color: $gold-color;
			}
		}
	}

	@media only screen and (min-width: 1000px) {
		#main {
			#part6,
			#part5 {
				h2 {
					font-size: 3rem;
					line-height: 4rem;
					width: 75%;
				}

				h1 {
					font-size: 4rem;
					line-height: 5rem;
				}
			}

			#part7 {
				h3 {
					width: 75%;
				}

				#form {
					width: 75%;

					.form50 {
						width: 100%;
					}
				}
			}

			input,
			textarea {
				font-size: 1.5rem;
			}

			.button {
				font-size: 2.5rem;
				padding: 3%;

				margin-top: 2%;
			}
		}
	}
</style>
