<template>
	<div id="main">
		<div id="part1" class="part">
			<h1>Co Możemy Dla <span class="gold">Ciebie</span> Zrobić?</h1>
		</div>
		<div id="part2" class="part">
			<h2>
				W dużym skrócie - sprawimy, że będziesz mieć <span class="gold">więcej klientów</span>. Są na to różne
				sposoby. <br /><br />Zawsze przeprowadzamy z naszymi klientami krótką rozmowę przed stworzeniem dla nich
				dokładnej oferty. Dzięki temu możemy zagwarantować najbardziej trafione rozwiązanie.
			</h2>
		</div>
		<div id="part3" class="part">
			<h2>A oto, na czym znamy się najlepiej:<br /><br /></h2>
			<div id="offerings">
				<div class="offering">
					<h3>Reklamy na Social Mediach</h3>
					<p>
						Głównie na <span class="gold">Facebooku</span> i <span class="gold">Instagramie</span>. To jest
						najskuteczniejsza forma dotarcia do jakichkolwiek klientów. Możemy wybrać naszą grupę docelową
						spośród kilku tysięcy możliwości i ich kombinacji, ustalić dzienny budżet co do złotówki i
						mierzyć wszystko przy pomocy bardzo dokładnych statystyk.
					</p>
					<p>
						Nie kończymy na napisaniu reklamy lub jej konfiguracji. Najlepszym sposobem na sprawdzenie
						skuteczności reklamy są jej statystyki. Im <span class="gold">niższy koszt</span> za kliknięcie
						lub klienta, tym lepiej.
					</p>
					<p>
						Możemy się wtedy skupić na ciągłym ulepszaniu reklam. Testujemy różne grupy docelowe, nagłówki,
						treść, ofertę, wezwanie do działania, elementy wizualne, sposób odpowiedzi na reklamę. Pierwsze
						efekty widzisz po tygodniu, ale doprowadzenie reklamy do perfekcji jest bardzo trudne i zajmuje
						bardzo długo. Na szczęście potrafimy to robić.
					</p>
					<p>
						I najważniejsze. Gwarantujemy Ci, że jeśli nasze reklamy nie będą lepsze od Twoich,
						<span class="gold">nie musisz nam nic płacić</span>.
					</p>
				</div>

				<div class="offering">
					<h3>Strony Internetowe</h3>
					<p>
						Jeśli nie masz <span class="gold">strony internetowej</span>, dla wielu klientów po prostu nie
						istniejesz. Pomagamy rozwiązać ten problem.
					</p>
					<p>
						Zamiast zlecić zaprogramowanie strony programiście lub zaprojektowanie strony designerowi,
						powinieneś skupić się na czymś innym. Ciężko popełnić błąd przy programowaniu niewielkiej
						strony, ciężko też zaprojektować brzydką stronę wyjętą żywcem z 2003 roku. Brzydka i wolna
						strona zadziałałaby, gdyby miała jedną rzecz. Najważniejsza na stronie jest…
					</p>
					<p>
						<span class="gold">Treść</span>. A dokładnie rzecz biorąc, copywriting. Sposób przekazania
						informacji w taki sposób, żeby klienci chcieli od razu od Ciebie coś kupić. Warto też dodać
						bardziej zaawansowane metody, jak na przykład lista klientów i newsletter.
					</p>
					<p>
						Tak się składa, że specjalizujemy się w copywritingu, mamy też w zespole programistów i
						designerów. Gwarantujemy, że strona internetowa, którą dla Ciebie zrobimy, będzie
						<span class="gold">skuteczna i zapewni Ci klientów</span>.
					</p>
				</div>

				<div class="offering">
					<h3>Pozycjonowanie</h3>
					<p>
						Tego chyba nie muszę tłumaczyć. Im wyżej w wynikach wyszukiwania wyświetli się Twoja
						<span class="gold">strona</span>, tym więcej osób na nią wejdzie. Proste i skuteczne.
					</p>
				</div>

				<div class="offering">
					<h3>Aplikacje webowe</h3>
					<p>
						Praktycznie każdy w naszym zespole jest z wykształcenia programistą.
						<span class="gold">Aplikacje webowe</span> to jest coś, od czego zaczynaliśmy i co robimy
						naprawdę dobrze. Jeśli więc potrzebujesz nie tylko marketingu, ale też działającej aplikacji -
						wiesz, gdzie nas znaleźć.
					</p>
				</div>

				<div class="offering">
					<h3>Sprawy techniczne</h3>
					<p>
						Cokolwiek dla Ciebie zrobimy,
						<span class="gold">nie zostawimy Cię z problemami technicznymi</span>. Napisanie reklamy oznacza
						doprowadzenie do momentu, gdzie wyświetla się klientom. Stworzenie strony - że znajduje się w
						Internecie i działa. Nie musisz martwić się o coś tak głupiego jak niedziałający system,
						wszystko bierzemy na siebie.
					</p>
					<p>
						A jeśli potrzebujesz długoterminowego wsparcia technicznego - na pewno uda nam się w tej sprawie
						dogadać.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LandingPage",
		setup() {
			document.title = "Co Możemy Dla Ciebie Zrobić?";
		},
	};
</script>

<style lang="scss" scoped>
	@import "../style/style.scss";

	#main {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.part {
			width: 100%;
			padding: 5% 2%;
			text-align: center;
		}

		h1 {
			font-size: 2.5rem;
			margin-bottom: 2rem;
			font-weight: 300;
		}

		h2 {
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}

		#offerings {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 1rem;
			width: 100%;
		}

		.offering {
			border: 1px solid $gold-color;
			padding: 2%;
			text-align: left;
		}

		h3 {
			font-size: 1.3rem;
			color: $gold-color;
			margin-top: 0;
		}

		p {
			margin: 1rem 0;
		}

		.gold {
			color: $gold-color;
		}
	}

	@media only screen and (min-width: 1000px) {
		#main {
			.part {
				padding: 2%;
			}

			h1 {
				font-size: 4rem;
			}

			h2 {
				font-size: 2rem;
			}

			#offerings {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto;
			}

			.offering:nth-child(1),
			.offering:nth-child(2) {
				grid-column: span 1;
			}

			.offering:nth-child(3),
			.offering:nth-child(4),
			.offering:nth-child(5) {
				grid-column: span 1;
			}

			.offering {
				margin: 1%;
				padding: 2%;

				& h3 {
					font-size: 2rem;
					text-align: center;
				}
			}

			h3 {
				font-size: 1.5rem;
			}

			p {
				font-size: 1.2rem;
			}
		}
	}
</style>
