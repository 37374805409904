<template>
	<div class="photographer-landing-page">
		<div class="headline">
			<h5>TYLKO DLA FOTOGRAFÓW, KTÓRZY CHCĄ MIEĆ WIĘCEJ KLIENTÓW</h5>
			<h1>Przełomowa Metoda Dla Fotografów, Żeby Mieć Więcej Klientów W Ciągu 90 Dni, Gwarantowane!</h1>
			<p>
				Odbierz Bezpłatną 30-Minutową Konsultację, Żeby Poznać Dokładny Plan, Jak Zacząć Umawiać Więcej Sesji
				Zdjęciowych (Wartość rozmowy: 570zł, teraz za darmo)
			</p>
			<button class="small-scroller" @click="scrollToForm">ZAREZERWUJ BEZPŁATNĄ ROZMOWĘ</button>
		</div>
		<div class="problem">
			<div class="without-photo">
				<h2>W końcu… Sprawdzony I Skuteczny Sposób, Żeby Umawiać Więcej Sesji Zdjęciowych!</h2>
				<p>
					Masz problemy z trafieniem do nowych klientów na Twoje sesje fotograficzne? A może już próbowałeś
					zająć się marketingiem, ale chciałbyś, żeby zaczął działać lepiej?<br /><br />
					Jeśli tak, to dobrze trafiłeś. Pomagamy fotografom takim jak Ty umawiać więcej sesji zdjęciowych i
					wypełnić kalendarz na kilka miesięcy do przodu w najkrótszym możliwym czasie.<br /><br />
					Do tej pory pokazaliśmy wielu fotografom, w jaki sposób mogą zacząć umawiać znacznie więcej sesji
					zdjęciowych z nowymi klientami, bez lat pracy i kosztownych błędów. Zapewniliśmy imponujące wyniki
					sobie i naszym klientom, i możemy zrobić to samo dla Ciebie.
				</p>
				<button class="big-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
			</div>
			<div class="with-photo-left">
				<h2>Gotowy Plan, Żeby Umawiać Więcej Sesji I Nie Wydawać Na To Fortuny</h2>
				<p>
					Pozyskanie nowych klientów na sesje zdjęciowe może być znacznie łatwiejsze niż się spodziewasz. Dużo
					fotografów po prostu nie wie, co dokładnie zrobić, żeby mieć więcej klientów - przez co często muszą
					odkrywać to sami.<br /><br />My zrobiliśmy za Ciebie całą ciężką pracę, więc będziesz musiał po
					prostu podążać krok po kroku za naszym planem, a zanim się obejrzysz, będziesz miał znacznie więcej
					klientów.
				</p>
				<img src="./photo1.png" />
			</div>
			<div class="with-photo-right">
				<h2>93% Fotografów Nigdy Nie Będzie Mieć Tylu Klientów, Ilu By Chcieli Bez Odpowiedniej Pomocy</h2>
				<p>
					Niestety, 93% fotografów nigdy nie będzie zadowolonych z ilości sesji zdjęciowych, jakie robią dla
					swoich klientów. To nie jest ich wina, po prostu nikt im nie pokazał, jak stworzyć stabilne i
					przewidywalne źródło klientów. Na szczęście, Ty możesz łatwo znaleźć się w tych lepszych 7%.<br /><br />Krok
					po kroku pokażemy Ci, co odkryliśmy, żebyś mógł zapełnić swój kalendarz sesjami zdjęciowymi. Poznasz
					metodę, której użyli nasi klienci, dzięki której dosłownie mieli aż za dużo sesji - i umawiali je na
					kilka miesięcy naprzód.<br /><br />Zarezerwuj swoją konsultację teraz i zobacz, jak Ty możesz
					osiągnąć podobny efekt, i jak możesz zrobić to szybciej, niż kiedykolwiek się spodziewałeś.
				</p>
				<img src="./photo2.png" />
			</div>
			<button class="small-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
		</div>
		<div class="bulletpoints">
			<h2>Oto Krótki Zarys Tego, Czego Dowiesz Się Podczas Bezpłatnej, 30-Minutowej Konsultacji</h2>
			<ul>
				<li>
					<b>Porozmawiamy O Twojej Sytuacji</b><br />Opowiesz nam, jak wygląda Twoja aktualna sytuacja. Dzięki
					temu powiemy Ci, co dokładnie powinno zadziałać dla Ciebie.
				</li>
				<li>
					<b>Sprawdzona Strategia Dla Fotografów, Której Użyliśmy, Żeby Umawiać Więcej Sesji</b><br />Pokażemy
					Ci niesamowicie skuteczną strategię, której użyliśmy, żeby pozyskać klientów dla nas i dla innych
					fotografów.
				</li>
				<li>
					<b
						>Jak Stworzyć Stabilne I Długotrwałe Źródło Dobrze Płatnych Klientów W Ciągu 90 Dni Bez
						Wydawania Na To Fortuny</b
					><br />
					Ten sposób to nie jest jakiś dziwny, internetowy „hack”, który przestanie działać trzy dni po naszej
					rozmowie. Otrzymasz długotrwałe i skuteczne źródło nowych klientów dla swojego studio.
				</li>
				<li>
					<b>Dlaczego To, Co Fotografowie Powtarzają O Poleceniach Jest Przestarzałe I Nieskuteczne</b
					><br />Wielu fotografów upiera się, że klient z poleceń jest najlepszy. Dowiesz się, dlaczego jest
					to tylko część prawdy i dlaczego nie można nigdy na nich w pełni polegać.
				</li>
				<li>
					<b>3 Kluczowe Trendy Wśród Fotografów, Którzy Mają Kalendarz Pełen Klientów</b><br />Dowiesz się,
					czego najlepiej płatni fotografowie używają, żeby ciągle umawiać coraz więcej klientów.
				</li>
			</ul>
			<button class="big-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
		</div>
		<div class="amplify">
			<div class="with-photo-left">
				<h2>Oszczędź Sobie Kosztownych Prób I Błędów, Używając Naszej Metody Na Pozyskanie Więcej Klientów</h2>
				<p>
					Dlaczego miałbyś spędzać dosłownie lata, próbując odkryć rozwiązanie tego problemu sam? Możesz
					oszczędzić ten czas i osiągnąć znacznie lepszy efekt, kiedy po prostu skorzystasz z porady eksperta
					i użyjesz naszej sprawdzonej metody na zdobycie więcej klientów. To działa dosłownie jak skrót,
					dający Ci bezpośredni dostęp do tego, co normalnie miałbyś dopiero za kilka lat.<br /><br />Jesteś
					gotowy, żeby zacząć umawiać więcej sesji w najkrótszym możliwym czasie? Mieć więcej możliwości,
					więcej klientów i mniej stresu, niż do tej pory? Jeśli tak, umów się na bezpłatną rozmowę, dopóki
					jest jeszcze dostępna.
				</p>
				<img src="./photo3.png" />
			</div>
			<button class="small-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
		</div>
		<div class="bulletpoints">
			<h2>Oto Jeszcze Kilka Rzeczy, Których Możesz Się Spodziewać Podczas Naszej Bezpłatnej Rozmowy</h2>
			<ul>
				<li>
					<b>Czego Nigdy Nie Powinieneś Robić, Jeśli Chcesz Umawiać Więcej Sesji</b><br />Proszę, nigdy nie
					rób tego podczas swojej drogi do umawiania więcej sesji, chyba, że chcesz pozostać z niewielką
					ilością klientów znacznie dłużej, niż to konieczne.
				</li>
				<li>
					<b>Dlaczego 88% Fotografów Przychodzi Do Nas Z Tym Samym Problemem - I Jak Go Rozwiązać</b><br />Ten
					błąd to bardzo popularne wśród fotografów niedopatrzenie. Kiedy zdasz sobie z niego sprawę,
					automatycznie zaczniesz mieć więcej klientów na sesje.
				</li>
				<li>
					<b>Kluczowa Umiejętność, Bez Której Nigdy Nie Będziesz Miał Wielu Klientów</b><br />Wielu fotografów
					nie potrafi skutecznie robić tej jednej rzeczy. Pokażemy Ci, co powinieneś umieć, żeby zacząć
					umawiać więcej sesji zdjęciowych, nawet mając mniej rozmów z klientami.
				</li>
				<li>
					<b>Klucz Do Zapełnienia Swojego Kalendarza Sesjami Tak Szybko, Jak To Możliwe</b><br />Jeden z
					najważniejszych elementów układanki, jaką jest umawianie sesji. Jeśli go poznasz, będziesz miał
					ogromną przewagę nad konkurencją.
				</li>
			</ul>
			<button class="big-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
		</div>
		<div class="guarantee">
			<img src="./guarantee.png" />
			<div class="without-photo">
				<h2>Pobijemy Wyniki Twojej Najlepszej Kampanii W Ciągu 90 Dni Albo Nie Musisz Nam Nic Płacić</h2>
				<p>
					Jeśli po naszej rozmowie postanowisz z nami współpracować, zapewnimy Ci gwarancję. Nie chcemy, żebyś
					brał całe ryzyko na siebie, przejmiemy jego największą część. <br /><br />Jeśli z jakiegoś powodu
					nie będziemy w stanie poprawić Twoich aktualnych wyników, oddamy Ci całe nasze wynagrodzenie za
					naszą pracę. Bez żadnych drobnych druczków, klauzul ani ukrytych sztuczek.
				</p>
				<button class="big-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
			</div>
		</div>
		<div class="cta">
			<div class="without-photo" id="form">
				<h2>Odbierz Swoją Bezpłatną, Niezobowiązującą 30-Minutową Konsultację (O wartości 570zł)</h2>
				<p>
					Podczas tej pozbawionej jakichkolwiek zobowiązań rozmowy, jeden z naszych ekspertów przedyskutuje z
					Tobą Twoją aktualną sytuację i powie Ci, co powinieneś zrobić, żeby zacząć mieć więcej klientów w
					najkrótszym możliwym czasie.<br /><br />Podczas tej rozmowy dowiesz się:
				</p>
				<ul>
					<li>
						Jak zacząć umawiać więcej sesji zdjęciowych w jak najkrótszym czasie bez zmniejszania cen ani
						wydawania fortuny na marketing
					</li>
					<li>
						Jaki jest dokładny proces, którego użyliśmy, żeby pomóc fotografom wypełnić ich kalendarz
						sesjami na kilka miesięcy do przodu
					</li>
					<li>
						Największy problem, z którym fotografowie przychodzą do nas, który nie pozwala im pozyskać
						nowych klientów – nieważne, jak mocno starają się go rozwiązać!
					</li>
				</ul>
				<p>
					To jest TYLKO dla ludzi, którzy poważnie myślą o tym, żeby faktycznie zacząć umawiać więcej sesji i
					mieć więcej klientów, a miejsca są ściśle ograniczone. Podaj swoje dane poniżej, żeby uniknąć
					rozczarowania i zabezpieczyć swoją konsultację.
				</p>
				<form>
					<div class="input-container name-container">
						<i class="fas fa-user"></i>
						<input type="text" class="name" placeholder="Imię" v-model="name" />
					</div>
					<div class="input-container email-container">
						<i class="fas fa-envelope"></i>
						<input type="email" class="email" placeholder="E-Mail" v-model="email" />
					</div>
					<div class="input-container phone-container">
						<i class="fas fa-phone"></i>
						<input type="text" class="phone" placeholder="Telefon" v-model="phone" />
					</div>
					<button type="submit" class="big-scroller" @click.prevent="sendInfo">
						UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ
					</button>
					<div class="warning" ref="warningRef" v-if="warningMessage">{{ warningMessage }}</div>
				</form>
			</div>
		</div>
		<div class="ps">
			<div class="without-photo">
				<p>
					P.S. Nadal tutaj jesteś?<br /><br />Cieszę się, że przejrzałeś całą naszą stronę. Dobry research
					jest naprawdę ważny.<br /><br />Pamiętaj, że ta rozmowa jest całkowicie bezpłatna i bez
					jakichkolwiek zobowiązań. Jeśli nie spodoba Ci się to, co usłyszysz albo uznasz, że chcemy Ci po
					prostu wcisnąć jakiś syf i tylko na Tobie zarobić, nie musisz umawiać się potem na nic innego.<br /><br />Możliwe,
					że czytałeś naszego ebooka. Chcemy dać Ci cały arsenał metod, jak pozyskać więcej klientów, umawiać
					więcej sesji i wypełnić swój kalendarz na kilka miesięcy do przodu. Nikt Ci jednak nie każe na nic
					konkretnego się zdecydować - to Ty podejmiesz decyzję.<br /><br />Proszę Cię tylko, żebyś podjął ją
					po tej konsultacji, a nie przed nią.
				</p>
				<button class="big-scroller" @click="scrollToForm">UMÓW SIĘ NA BEZPŁATNĄ ROZMOWĘ</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { inject, ref, reactive, toRefs } from "vue";
	import axios from "axios";

	export default {
		name: "PhotographerLandingPage",
		setup() {
			document.title = "Fotografowie - Darmowa Sesja Strategiczna";
			const endpoint = inject("g_endpoint");

			const formData = reactive({
				name: "",
				email: "",
				company: "",
				phone: "",
				question: "",
				message: "",
			});

			const warningMessage = ref("");
			const warningRef = ref(null);

			const validateField = (condition, message) => {
				if (!condition) {
					warningMessage.value = message;
					if (warningRef.value) {
						warningRef.value.style.visibility = "visible";
					}
					return false;
				}
				return true;
			};

			const sendInfo = async () => {
				// Walidacja pól
				if (!validateField(formData.name !== "", "Podaj imię.")) return;
				if (!validateField(/^\S+@\S+\.\S+$/.test(formData.email), "Podaj poprawny adres email.")) return;
				if (!validateField(/^\d{9}$/.test(formData.phone), "Numer telefonu powinien składać się z 9 cyfr."))
					return;

				try {
					// Przygotowanie danych do wysyłki, bez modyfikacji formData
					const dataToSend = {
						name: formData.name,
						email: formData.email,
						company: "brak",
						phone_number: formData.phone,
						main_question: "brak",
						message: "brak",
					};

					const jsonFormData = JSON.stringify(dataToSend);

					const response = await axios.post(`${endpoint}messages/`, jsonFormData, {
						withCredentials: true,
						headers: { "Content-Type": "application/json" },
					});

					// Zresetowanie formularza po udanej wysyłce
					Object.keys(formData).forEach((key) => (formData[key] = ""));
					warningMessage.value = "Wiadomość wysłana poprawnie";
				} catch (error) {
					console.error("Błąd podczas wysyłania danych:", error);
					warningMessage.value = "Wiadomość nie została wysłana";
				}
			};

			const scrollToForm = () => {
				const formElement = document.getElementById("form");
				if (formElement) {
					formElement.scrollIntoView({ behavior: "smooth" });
				}
			};

			return { ...toRefs(formData), warningMessage, sendInfo, scrollToForm, warningRef };
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/style/style.scss";

	.photographer-landing-page {
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
		max-width: 1200px;
		margin: auto;
		gap: 50px;
		padding-top: 50px;

		img {
			user-select: none;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		p,
		li {
			margin: 0;
		}

		h5 {
			font-size: 1.4rem;
		}

		h2 {
			font-size: 3rem;
		}

		h1 {
			font-size: 3.5rem;
		}

		p,
		li {
			font-size: 1.3rem;
		}

		.gold {
			color: $gold-color;
		}

		button {
			background-color: $gold-color;
			color: white;
			border: none;
			padding: 10px 20px;
			font-size: 1.3rem;
			width: 100%;
			padding: 30px;
			cursor: pointer;
			border-radius: 1000px;
			transition: 0.3s ease-in-out;

			&:hover {
				background-color: white;
				color: $gold-color;
			}
		}

		form {
			width: 75%;
			margin: auto;
			display: flex;
			flex-direction: column;

			.input-container {
				position: relative;
				width: 100%;
				margin-bottom: 20px;

				input,
				textarea {
					width: 100%;
					padding: 15px 10px 15px 40px;
					border-radius: 5px;
					border: 1px solid #ddd;
					font-size: 1.2rem;
				}

				textarea {
					resize: none;
					min-height: 120px;
				}

				i {
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
					color: $gold-color;
					font-size: 1.5rem;
				}
			}
		}

		.headline {
			width: 80%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 50px;
			margin: auto;
			padding-bottom: 50px;

			button {
				width: 60%;
			}
		}

		.problem,
		.amplify,
		.guarantee,
		.cta,
		.ps {
			width: 90%;
			display: flex;
			flex-direction: column;
			gap: 50px;
			margin: auto;
			margin-bottom: 50px;

			button {
				width: 60%;
				margin: auto;
			}
		}

		.guarantee {
			margin-top: 50px;
			margin-bottom: 50px;

			img {
				width: 30%;
				border-radius: 5px;
				margin: auto;
			}
		}

		.without-photo {
			width: 70%;
			margin: auto;
			display: flex;
			flex-direction: column;
			gap: 30px;

			h2 {
				text-align: center;
				margin-bottom: 20px;
			}

			p,
			button {
				width: 75%;
				margin: auto;
			}

			ul {
				width: 75%;
				margin: auto;
				padding-left: 20px;

				li {
					margin-bottom: 20px;
				}
			}

			form button {
				width: 100%;
			}
		}

		.with-photo-left,
		.with-photo-right {
			width: 100%;
			margin: auto;
			display: grid;
			grid-template-rows: auto auto;
			gap: 30px;
			align-items: center;

			img {
				width: 500px;
				border-radius: 5px;
				grid-area: image;
				object-fit: cover;
			}

			h2 {
				grid-area: title;
			}

			p {
				grid-area: text;
			}
		}

		.with-photo-left {
			grid-template-columns: auto 1fr;
			grid-template-areas:
				"image title"
				"image text";
		}

		.with-photo-right {
			grid-template-columns: 1fr auto;
			grid-template-areas:
				"title image"
				"text image";
		}

		.bulletpoints {
			width: 90%;
			display: flex;
			flex-direction: column;
			gap: 50px;
			margin: auto;

			h2 {
				text-align: center;
			}

			ul {
				width: 70%;
				margin: auto;
				padding-left: 20px;

				li {
					margin-bottom: 20px;
				}
			}

			button {
				width: 70%;
				margin: auto;
			}
		}

		/* Responsive styles */
		@media (max-width: 768px) {
			padding-top: 0;
			padding-bottom: 0;

			h1 {
				font-size: 2.5rem;
			}

			h2 {
				font-size: 1.8rem;
			}

			h5 {
				font-size: 1.2rem;
			}

			p,
			li {
				font-size: 1.1rem;
			}

			button {
				padding: 15px;
			}

			form {
				width: 100%;
			}

			.headline {
				width: 100%;
				padding-bottom: 0;
				gap: 20px;

				h5 {
					display: none;
				}

				button {
					width: 100%;
				}
			}

			.problem,
			.amplify,
			.guarantee,
			.cta,
			.ps {
				width: 100%;

				button {
					width: 100%;
				}
			}

			.guarantee {
				img {
					width: 50%;
				}
			}

			.without-photo {
				width: 100%;
				gap: 20px;

				p,
				button {
					width: 100%;
				}

				ul {
					width: 100%;
				}

				form button {
					width: 100%;
				}
			}

			.with-photo-left,
			.with-photo-right {
				width: 100%;
				grid-template-rows: auto auto auto;

				img {
					width: 100%;
				}
			}

			.with-photo-left {
				grid-template-columns: auto;
				grid-template-areas:
					"image"
					"title"
					"text";
			}

			.with-photo-right {
				grid-template-columns: auto;
				grid-template-areas:
					"image"
					"title"
					"text";
			}

			.bulletpoints {
				width: 100%;

				ul {
					width: 100%;
				}

				button {
					width: 100%;
				}
			}
		}
	}
</style>
