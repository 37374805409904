<template>
	<div id="footer">
		<div id="socials">
			<a href="https://www.facebook.com/profile.php?id=61556516234527" target="_blank"
				><img src="../assets/facebook.png"
			/></a>
			<a href="https://www.instagram.com/rmd.solutions/" target="_blank"><img src="../assets/instagram.png" /></a>
			<a href="https://www.threads.net/@rmd.solutions" target="_blank"><img src="../assets/threads.png" /></a>
			<a href="https://twitter.com/Rafal_RMD" target="_blank"><img src="../assets/x.png" /></a>
			<a href="https://www.linkedin.com/company/rmd-solutions/" target="_blank"
				><img src="../assets/linkedin.png"
			/></a>
		</div>
		<a>Created by RMD Solutions. 2023 - now</a>
	</div>
</template>
<script>
	export default {
		setup() {},
	};
</script>
<style lang="scss" scoped>
	@import "../style/style.scss";
	#footer {
		border-top: 1px solid $border-color2;

		background-color: $main-color;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		padding-top: 3%;
		padding-bottom: 3%;

		#socials {
			display: flex;
			margin-bottom: 20px;

			img {
				width: 40px;
				cursor: pointer;
				margin-right: 20px;
			}
		}
	}
</style>
