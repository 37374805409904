import { createRouter, createWebHistory } from "vue-router";
import landingPage from "@/components/landingPage.vue";
import contactPage from "@/components/contactPage.vue";
import blogPage from "@/components/blog/blogPage.vue";
import guidePage from "@/components/guide/guidePage.vue";
import thankYouPage from "@/components/guide/thankYouPage.vue";
import portfolioPage from "@/components/portfolio/portfolioPage.vue";
import opinionsPage from "@/components/portfolio/opinionsPage.vue";
import aboutUsPage from "@/components/aboutUsPage.vue";
import offerPage from "@/components/offerPage.vue";
import analysisPage from "@/components/analysisPage.vue";
import articlePage from "@/components/blog/articlePage.vue";
import photographerOptinPage from "@/components/blog/Niches/Photographers/photographerOptinPage.vue";
import photographerLandingPage from "@/components/blog/Niches/Photographers/photographerLandingPage.vue";

const routes = [
	{
		path: "/",
		name: "landingPage",
		component: landingPage,
	},
	{
		path: "/kontakt",
		name: "contactPage",
		component: contactPage,
	},
	{
		path: "/opinie",
		name: "opinionsPage",
		component: opinionsPage,
	},
	{
		path: "/portfolio",
		name: "portfolioPage",
		component: portfolioPage,
	},
	{
		path: "/blog",
		name: "blogPage",
		component: blogPage,
	},
	{
		path: "/blog/:id/:slug",
		name: "articlePage",
		component: articlePage,
	},
	{
		path: "/poradnik",
		name: "guidePage",
		component: guidePage,
	},
	{
		path: "/oferta",
		name: "offerPage",
		component: offerPage,
	},
	{
		path: "/oNas",
		name: "aboutUsPage",
		component: aboutUsPage,
	},
	{
		path: "/analiza",
		name: "analysisPage",
		component: analysisPage,
	},
	{
		path: "/dziekujemy",
		name: "thankYouPage",
		component: thankYouPage,
	},
	{
		path: "/fotografowie-ebook",
		name: "photographerOptinPage",
		component: photographerOptinPage,
	},
	{
		path: "/fotografowie-rozmowa",
		name: "photographerLandingPage",
		component: photographerLandingPage,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});

export default router;
